// Custom Theming for Angular Material
@use '~@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


$bean-app-orange: (
  50: #fdf3e3,
  100: #fbe1ba,
  200: #f8ce8d,
  300: #f6ba63,
  400: #f4ab47,
  500: #f29e38,
  600: #ed9334,
  700: #e68530,
  800: #df772d,
  900: #d36229,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);

$bean-app-brown: (
  50: #ffe3c4,
  100: #f4c0a4,
  200: #d59c80,
  300: #b47959,
  400: #9c5f3d,
  500: #834521,
  600: #783c1c,
  700: #683014,
  800: #5a230e,
  900: #4a1401,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);

$bean-app-web-primary: mat.define-palette($bean-app-orange);
$bean-app-web-accent: mat.define-palette($bean-app-brown);

// The warn palette is optional (defaults to red).
$bean-app-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bean-app-web-theme: mat.define-light-theme((
  color: (
    primary: $bean-app-web-primary,
    accent: $bean-app-web-accent,
    warn: $bean-app-web-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bean-app-web-theme);

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
  $font-family: "'Open Sans', sans-serif;",
  $headline: mat.define-typography-level(32px, 48px, 600),
  $title: mat.define-typography-level(24px, 40px, 600),
  $body-1: mat.define-typography-level(16px, 24px, 500)
);

@include mat.all-component-typographies($custom-typography);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body {
  background-color: #fafafa;;
  margin: 0; font-family: 'Open Sans', "Helvetica Neue", sans-serif;
}


app-root {
  display: block;
  height: 100%;
}

main {
  // margin: 1em;
  // padding: 1em;
  // background-color: white;
  // box-shadow: 0 2px 25px rgba(0,0,0,.1);
  // border-radius: 4px;
  // border: solid 1px rgba(0, 0, 0, 0.12);
}

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

header {
  margin: 1em;
}

main {
  margin: 1em;
}
